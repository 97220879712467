import React, { useState, useEffect } from 'react';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import BlackLogo from '../../../images/bendable-labs-logo_black.svg';

import { SPACING, MOBILE_WIDTH } from '../../../theme.js';

import './Headline.css';

const Headline = (props) => {
  const {
    text = 'Because America needs better job pathways. And better jobs.'
  } = props;

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const isMobile = width <= MOBILE_WIDTH;  // MaterialUI SM equivalent

  return (
    <Stack sx={{
        paddingLeft: SPACING.MAIN_LEFT,
        paddingRight: SPACING.MAIN_RIGHT
      }}
    >
      {/* Desktop Menu */}
      { !isMobile && 
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box
            sx={{
              height: { xs: '32px' , md: '64px' },
              flexGrow: 1,
            }}
          >
            <a href="/" alt-text="Go to home page">
              <img src={BlackLogo} height="100%" alt="bendable logo" />
            </a>
          </Box>
          <Box
            className="headline__contact"
            sx={{
              display: "flex",
              flexGrow: 1,
              alignItems: "center",
              justifyContent: "flex-end",
              fontFamily: 'Sharp Sans'
            }}
          >
            <a href="/contact">
              Contact
            </a>
          </Box>
        </Box>
      }
      {/* Page Title */}
      <Typography
        component="h1"
        variant="h1"
        sx={{
          width: {xs: '100%', sm: '70%'},
          paddingTop: {xs: '40px', sm: '64px'},
          paddingBottom: {xs: '40px', sm: '64px'}
        }}
      >
        {text}
      </Typography>
    </Stack>
  )
}

export default Headline;
