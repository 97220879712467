import React from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'

import Headline from '../components/blocks/shared/Headline';

import { COLORS, SPACING } from '../theme.js';

const WhatWeBelievePage = (props) => {

  return (
    <Stack>
      <Headline text="What we believe" />
      <Stack
        sx={{
          paddingLeft: SPACING.MAIN_LEFT,
          paddingRight: SPACING.MAIN_RIGHT,
          lineHeight: '1.5'
        }}
      >
        <Divider sx={{ marginBottom: '70px' }} />
        <Typography
          variant="h2"
          sx={{
            color: COLORS.BLUE,
          }}
        >
          Among the fundamental “promises of American society,”
          the great management thinker Peter Drucker&nbsp;
          <Link 
            href="https://www.amazon.com/Concept-Corporation-Peter-F-Drucker/dp/1560006250"
            target="_blank"
            sx={{
              color: COLORS.BLUE,
              textDecorationColor: COLORS.BLUE
            }}
          >
            posited
          </Link>, is
          that every individual should have “a rational chance of
          equal opportunities.”
        </Typography>
        <br />
        <Typography
          variant="body1"
        >
          But in reality, how someone’s life turns out often has
          less to do with their abilities than with the&nbsp;
          <Link
            href="https://www.opportunityatlas.org/"
            target="_blank"
            sx={{
              color: COLORS.BLACK,
              textDecorationColor: COLORS.BLACK
            }}
          >
            zip code
          </Link>
          &nbsp;they’re born into.
        </Typography>
        <br />
        <Typography
          variant="body1"
        >
          In an attempt to remedy things, certain experts put an
          emphasis on providing more pathways into good jobs.
          Others are primarily focused on improving bad jobs.
        </Typography>
        <br />
        <Typography
          variant="body1"
        >
          At Bendable Labs, we believe both are essential.
        </Typography>
        <br />
        <Typography
          variant="body1"
        >
          Every person should be afforded ample knowledge- and
          skill-building opportunities that will give them a
          chance to get ahead, no matter their level of formal
          education, the community they call home, or how they
          express their particular brand of intelligence.
        </Typography>
        <br />
        <Typography
          variant="body1"
        >
          For some, this will mean attending a four-year university;
          for others, mastering a trade at a community college;
          for others, learning on the job; for others still, learning
          at their local public library.
        </Typography>
        <br />
        <Typography
          variant="body1"
        >
          “In short,” as the educator James Bryant Conant&nbsp;
          <Link
            href="https://www.theatlantic.com/magazine/archive/1940/05/education-for-a-classless-society/305254/"
            target="_blank"
            sx={{
              color: COLORS.BLACK,
              textDecorationColor: COLORS.BLACK              
            }}
          >
            declared
          </Link>
          &nbsp;more than 80 years ago, “we must explore every method of developing
          the individual capacity” of every person.
        </Typography>
        <br />
        <Typography
          variant="body1"
        >
          And yet as crucial as learning is, too many have come to see it
          as a panacea. For employers, investing in training has become
          a convenient substitute for providing their workers with sufficient
          pay, adequate healthcare and a real voice.
        </Typography>
        <br />
        <Typography
          variant="body1"
          sx={{
            marginBottom: '120px'
          }}
        >
          A good job shouldn’t have to be the next job, predicated on acquiring
          new skills and moving up the ladder. Every job should be a good job.
          And a good job starts with paying a&nbsp;
          <Link
            href="https://livingwageforus.org/"
            target="_blank"
            sx={{
              color: COLORS.BLACK,
              textDecorationColor: COLORS.BLACK     
            }}
          >
            living wage
          </Link>
          .
        </Typography>
      </Stack>
    </Stack>
  );
}

export default WhatWeBelievePage;
