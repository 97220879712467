import React, { useState, useEffect } from 'react';

import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import GoIcon from '../../../images/icon_link-arrow.svg';

import { COLORS, SPACING } from '../../../theme';

import './Media.css';
// import mediaList from '../../../data/media.json';
import callApi from '../../../apiService';


const Media = (props) => {

  const [allMedia, setAllMedia] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const apiResponse = await callApi('getBlMedia', null, null);
      console.log('Media useEffect() apiResponse: ', apiResponse);
      setAllMedia(apiResponse?.data?.payload || []);
    };
    fetch();
  }, []);

  return (
    <Stack sx={{
      background: '#8ED6E8',
    }}>
      <Box
        sx={{
          marginTop: '100px',
          marginLeft: SPACING.MAIN_LEFT,
          marginRight: SPACING.MAIN_RIGHT,
          marginBottom: '100px'
        }}
      >
        <Typography
          variant="h2"
          sx={{
            paddingBottom: '40px',  // Still need to adjust this for breakpoints
          }}
        >
          Pieces by us or about us
        </Typography>
        <List>
          {allMedia.slice(0,3).map((media, index) => (
              <ListItem key={`${index}`}
                sx={{
                  marginBottom: '20px',
                  padding: '0',
                  flexDirection: 'column',
                }}
              >
                <Link
                  href={media.url}
                  underline='none'
                  sx={{
                    color: '#297687',
                    width: '100%'
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: COLORS.BLUE,
                      marginBottom: '10px'
                    }}
                  >
                    {media.date} | {media.source}
                  </Typography>
                  <Typography
                    variant="h2"
                    sx={{
                      color: COLORS.BLUE,
                      marginBottom: '10px'
                    }}
                  >
                    {media.title}
                  </Typography>
                  <Box>
                    <img className="media-link-arrow" src={GoIcon} alt-text="Go to link" />
                  </Box>
                </Link>
                {
                  index !== allMedia.length - 1 &&
                    <Divider sx={{
                        width: '100%',
                        marginTop: '20px',
                        borderColor: COLORS.BLACK
                      }}
                    />
                }
              </ListItem>
            )
          )}
        </List>
      </Box>

    </Stack>
  )
}

export default Media;
