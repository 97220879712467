import React from 'react';

import Box from '@mui/material/Box';

import HeroVideo from '../../../images/hero-video-web.mp4';
import HeroVideoGif from '../../../images/hero-video-mobile.gif';

const BendableHeroVideo = (props) => {

  // 'muted' param is not allowing in React, so we use this work around...
  return (
    <div dangerouslySetInnerHTML = {{
        __html: `
          <video
            width="100%"
            autoplay
            loop
            muted
            playsinline
          >
            <source src=${HeroVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        `
      }}
    />
  );
};

export default BendableHeroVideo;
