/**
 * Other stock photos for this component:
 * 
 * https://bendable.s3.us-west-1.amazonaws.com/hero-j.jpg
 * https://bendable.s3.us-west-1.amazonaws.com/hero-d.jpg (square!!)
 * 
 * 
 */

import React from 'react';

import Box from '@mui/material/Box';

const BendableLabsHero = (props) => {
  const {
    imageUrl = 'https://bendable.s3.us-west-1.amazonaws.com/mg_libraries_hero1.jpg',        // default hero image
  } = props;

  return (
    <Box
      id="bendable-labs-hero-wrapper"
      sx={{
        width: '100%',
        height: { xs: '338px', sm: '450px', md: '650px', lg: '100%' },
        minHeight: { xs: '338px', sm: '450px', md: '650px' },
        background: `url(${imageUrl}) no-repeat center center`,
        backgroundSize: 'cover',
      }}
    >
      &nbsp;
    </Box>
  );
};

export default BendableLabsHero;