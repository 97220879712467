import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ConfirmationDialog(props) {
  const {
    shouldOpen,
    fullWidth = true,
    heading = '',
    confirmationText = 'Confirm text here...',
    cancelButton = 'Cancel',
    confirmButton = 'Confirm',
    onClose = () => {},
  } = props;


  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(shouldOpen);
  }, [shouldOpen]);


  const handleClose = (action) => {
    // setOpen(false);
    onClose(action);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        fullWidth={fullWidth}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
        }}
      >
        <DialogTitle id="alert-dialog-title" sx={{ fontFamily: 'Sharp Sans', }}>
          {heading}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ fontFamily: 'Sharp Sans', }}>
            {confirmationText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(event) => { handleClose('cancel') }}>{cancelButton}</Button>
          <Button onClick={(event) => { handleClose('confirm') }}>{confirmButton}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}