import React, { useState, useEffect } from 'react';
import _, { update } from 'lodash-es';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';

import Headline from '../components/blocks/shared/Headline';
import MediaItem from '../components/blocks/AdminMediaPage/MediaItem';
import callApi from '../apiService.js';

const emptyMediaItem = {
  date: '',
  source: '',
  title: '',
  url: '',
};

const AdminMediaPage = (props) => {

  const { enqueueSnackbar } = useSnackbar();

  const [triggerFetch, setTriggerFetch] = useState(0);
  const [allMedia, setAllMedia] = useState([]);
  const [newMediaItem, setNewMediaItem] = useState(emptyMediaItem);
  const [updatePassword, setUpdatePassword] = useState('');

  useEffect(() => {
    console.log('Media useEffect() triggerFetch: ', triggerFetch);
    const fetch = async () => {
      const apiResponse = await callApi('getBlMedia', null, null);
      console.log('Media useEffect() apiResponse: ', apiResponse);
      const media = apiResponse?.data?.payload || [];
      // const sortedMedia = _.sortBy(media, (item) => {
      //   const dateTime = DateTime.fromFormat(item.date, 'L.d.yyyy');
      //   return -(dateTime.toMillis());      // descending sort
      // });
      setAllMedia(media);
    };
    fetch();
  }, [triggerFetch]);


  const handleDataChange = (updateData) => {
    if (updateData._id) {
      setAllMedia((prevState) => {
        const newState = [...prevState];
        const index = _.findIndex(newState, (item) => (item._id === updateData._id));
        console.log('AdminMediaPage.handleDataChange() index: ', index);
        newState[index][updateData.field] = updateData.value;
        console.log('AdminMediaPage.handleDataChange() newState: ', newState);
        return newState;
      });
    } else {
      setNewMediaItem((prevState) => {
        const newState = {...prevState};
        newState[updateData.field] = updateData.value;
        console.log('AdminMediaPage.handleDataChange() newState: ', newState);
        return newState;
      });
    }
  };

  const handleSave = async (data) => {
    const {
      _id,
    } = data;

    let upsertData;
    if (_id) {
      upsertData = _.find(allMedia, (item) => (item._id === _id));
    } else {
      upsertData = newMediaItem;
    };
    upsertData.updatePassword = updatePassword;
    console.log('AdminMediaPage.handleSave() upsertData: ', upsertData);

    const apiResponse = await callApi('upsertBlMedia', null, upsertData);
    if (apiResponse?.data?.status === 'error') {
      enqueueSnackbar(apiResponse?.data?.payload, { variant: 'error' });
    } else {
      setNewMediaItem(emptyMediaItem);
    }

    setTimeout(() => {
      setTriggerFetch((prevState) => {
        return prevState + 1;
      });
    }, 500);

  };

  const handleDelete = async (data) => {
    const {
      _id,
    } = data;

    const deleteData = {
      updatePassword: updatePassword,
      _id: _id,
    };

    const apiResponse = await callApi('deleteBlMedia', null, { params: deleteData });
    if (apiResponse?.data?.status === 'error') {
      enqueueSnackbar(apiResponse?.data?.payload, { variant: 'error' });
    }
    
    setTimeout(() => {
      setTriggerFetch((prevState) => {
        return prevState + 1;
      });
    }, 500);

  };

  return (
    <Stack>
      <Headline text="Media Admin" />

      <Stack direction="row"
        sx={{
          marginLeft: '20px',
        }}
      >
        <TextField
          label="Password for updates"
          variant="outlined"
          value={updatePassword}
          onChange={(event) => { setUpdatePassword(event.target.value); }}
          sx={{
            width: '400px',
            marginBottom: '20px',
          }}
        />
      </Stack>


      <Stack direction="column"
        sx={{
          marginLeft: '20px',
          marginBottom: '30px',
          marginRight: '20px',
        }}
      >

        <MediaItem
          key={-1}
          data={newMediaItem}
          onDataChange={handleDataChange}
          onSave={handleSave}
        />

        {
          allMedia.map((mediaData, index) => {
            return (
              <MediaItem
                key={index}
                data={mediaData}
                onDataChange={handleDataChange}
                onSave={handleSave}
                onDelete={handleDelete}
              />
            )
          })
        }
      </Stack>


    </Stack>
  )
}

export default AdminMediaPage;
