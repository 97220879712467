import React, { useState, useEffect } from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider'

import Headline from '../components/blocks/shared/Headline';
import MediaGridItem from '../components/blocks/PerspectivePage/MediaGridItem';

import GoIcon from '../images/icon_link-arrow.svg';

import { COLORS, SPACING } from '../theme.js';

// import media from '../data/media.json';
import callApi from '../apiService.js';

const PerspectivePage = (props) => {

  const [allMedia, setAllMedia] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const apiResponse = await callApi('getBlMedia', null, null);
      console.log('Media useEffect() apiResponse: ', apiResponse);
      setAllMedia(apiResponse?.data?.payload || []);
    };
    fetch();
  }, []);

  return (
    <Stack>
      <Headline text="Perspective" />
      <Stack
        sx={{
          paddingLeft: SPACING.MAIN_LEFT,
          paddingRight: SPACING.MAIN_RIGHT,
          lineHeight: '1.5'
        }}
      >
        <Divider sx={{
            marginBottom: '70px'
          }}
        />
        <Grid container spacing={2}
          sx={{
            marginBottom: '70px'
          }}
        >
          {
            allMedia.map((article, index) => {
              return (
                <MediaGridItem article={article} key={index} />
              )
            })
          }
        </Grid>
      </Stack>
    </Stack>
  )
}

export default PerspectivePage;
