import React from 'react';
import { useParams } from "react-router-dom";
import ReactMarkdown from 'react-markdown'

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

import Headline from '../components/blocks/shared/Headline';
import EmployeeImage from '../components/blocks/shared/EmployeeImage';

import { COLORS, SPACING } from '../theme.js';

import employeeList from '../data/employees.json';

const EmployeePage = (props) => {
  let { employeeId } = useParams();

  let employeeData = employeeList.find((employee) => employee.employeeId === employeeId);

  let employeePronoun = employeeData.gender === "F" ? "She" : "He";

  return (
    <Stack>
      <Headline text="Who we are" />
      <Link href="/who-we-are" underline="none"
        sx={{
          paddingLeft: SPACING.MAIN_LEFT,
          paddingRight: SPACING.MAIN_RIGHT,
          marginTop: "-50px",
          marginBottom: "30px",
          fontFamily: "Sharp Sans",
          fontSize: "20px",
          color: "#828990",
          textDecoration: "underline"
        }}
      >
        Back
      </Link>
      <Stack
        sx={{
          paddingTop: {xs: '64px'},
          paddingBottom: {xs: '64px'},
          paddingLeft: SPACING.MAIN_LEFT,
          paddingRight: SPACING.MAIN_RIGHT,
          backgroundColor: COLORS.BG_RED
        }}
      >
        <Box>
          <EmployeeImage employee={employeeId} inGrid={false} />
          <Stack direction="row">
            <Typography
              variant="h2"
              sx={{
                color: COLORS.RED,
                marginTop: "20px"
              }}
            >
              {employeeData.name}&nbsp;
            </Typography>
            <Typography
              variant="body1"
              sx={{
                display: "flex",
                alignItems: "flex-end",
                textTransform: "uppercase",
                color: COLORS.RED
              }}
            >
              {employeeData.title}
            </Typography>
          </Stack>

          <Typography
            variant="subtitle1"
            sx={{
              marginTop: "20px",
              color: COLORS.RED,
              lineHeight: "1.9"
            }}
          >
            <ReactMarkdown>
              {employeeData.content}
            </ReactMarkdown>
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              marginTop: "20px",
              color: COLORS.RED
            }}
          >
            {employeePronoun} can be reached at&nbsp;
            <Link 
              sx={{ color: COLORS.RED, textDecorationColor: COLORS.RED }} 
              href={`mailto: ${employeeData.contact}`}
            >
              {employeeData.contact}
            </Link>
            .
          </Typography>
        </Box>
      </Stack>
    </Stack>
  )
}

export default EmployeePage;
