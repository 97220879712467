import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import ButtonBase from '@mui/material/ButtonBase';

import NavigationMenuItems from './NavigationMenuItems';

import { StyledDrawer } from './styledDrawer';

import './Navigation.css';

import { COLORS } from '../../theme.js'

import WhiteLogoTextless from '../../images/logo-white-no-text.png';

const LOGO_STATES = {
  INITIAL: "slide-start",  // Note: Currently no styling applied with this class
  SLIDE_IN: "slide-in",
  SLIDE_OUT: "slide-out"
}

const Navigation = (props) => {
  const [open, setOpen] = React.useState(false);
  const [logoState, setLogoState] = useState(LOGO_STATES.INITIAL);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = (event) => {
    const windowScroll = document.body.scrollTop || document.documentElement.scrollTop;

    if (windowScroll > 300) {
      setLogoState(LOGO_STATES.SLIDE_IN);
    }
    if (windowScroll === 0) {
      setLogoState(LOGO_STATES.SLIDE_OUT)
    }
  };

  const handleDrawerOpen = () => {
    document.body.style.overflow = "hidden";
    setOpen(true);
  };

  const handleDrawerClose = () => {
    document.body.style.overflow = "scroll";
    setOpen(false);
  };

  const scrollToTop = () => {
    // TODO: If not on homepage, should we go to home?
    window.scrollTo(0, 0);
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <StyledDrawer
        className={open ? "nav-drawer-open" : "nav-drawer-closed"}
        variant="permanent"
        open={open}
      >
        {
          open &&
          <Box
            sx={{
              width: '64px',
              background: COLORS.BG_DARKGOLD,
              position: 'absolute',
              top: 0,
              bottom: 0,
              zIndex: 10,
            }}
          >
            <ButtonBase
              onClick={handleDrawerClose}
              sx={{
                fontFamily: 'Sharp Sans',
                fontSize: '23px',
                fontWeight: 700,
                height: '64px',
                marginTop: '40px',
                transform: 'rotate(90deg)',
                ...(!open && { display: 'none' }),
              }}
            >
              <span>Close</span>
            </ButtonBase>
          </Box>
        }

          <Box
            id="slider"
            className={logoState}
          >
            <Box
              sx={{
                background: '#008AAF'
              }}
            >
              <IconButton
                className="drawer__scroll-to-top-button"
                color="#303836"
                aria-label="Scroll to top"
                onClick={scrollToTop}
                edge="start"
                sx={{
                  marginLeft: '1px',
                  marginTop: '30px',
                  marginBottom: '30px',
                  ...(open && { display: 'none' }),
                }}
              >
                <img className="" width="45px" src={WhiteLogoTextless} alt="Scroll to top"
                  loading="lazy"
                />
              </IconButton>
            </Box>

            <ButtonBase
              className="drawer__open-button"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              sx={{
                fontFamily: 'Sharp Sans',
                fontSize: '23px',
                fontWeight: 700,
                height: '64px',
                marginTop: '40px',
                transform: 'rotate(90deg)',
                ...(open && { display: 'none' }),
              }}
            >
              Menu
            </ButtonBase>
          </Box>

        <NavigationMenuItems open={open} />

      </StyledDrawer>
    </Box>
  );
}

export default Navigation;
