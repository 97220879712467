import React, { useState, useEffect } from 'react';

import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Stack from '@mui/material/Stack';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Typography from '@mui/material/Typography';

import Baker from '../../../images/partner-logo_baker-taylor.png';
import Harbor from '../../../images/partner-logo_harbor-freight.png';
import Burning from '../../../images/partner-logo_bgi-institute.png';
import Lightcast from '../../../images/partner-logo_lightcast.png';
import Csl from '../../../images/partner-logo_california-state-library.png';
import Epg from '../../../images/partner-logo_epg.png';

import { SPACING, MOBILE_WIDTH } from '../../../theme';

import './Partners.css'


const Partners = (props) => {
  const theme = useTheme();

  //const [width, setWidth] = useState(window.innerWidth);

  // function handleWindowSizeChange() {
  //     setWidth(window.innerWidth);
  // }

  // useEffect(() => {
  //     window.addEventListener('resize', handleWindowSizeChange);
  //     return () => {
  //         window.removeEventListener('resize', handleWindowSizeChange);
  //     }
  // }, []);  
  // const isMobile = width <= MOBILE_WIDTH;  // MaterialUI SM equivalent
  // const numCols = !isMobile ? 3 : 2;
  // const rowHeight = !isMobile ? 80 : 60;

  const logos = [
    { name: "Baker & Taylor", src: Baker },
    { name: "The Burning Glass Institute", src: Burning },
    { name: "California State Library", src: Csl },
    { name: "Harbor Freight", src: Harbor },
    { name: "Lightcast", src: Lightcast },
    { name: "Maine State Library", src: Epg },
  ];

  let numCols = 2;
  if (useMediaQuery(theme.breakpoints.up('sm'))) numCols = 3;

  let gap = 20;
  if (useMediaQuery(theme.breakpoints.up('sm'))) gap = 30;
  if (useMediaQuery(theme.breakpoints.up('md'))) gap = 30;
  if (useMediaQuery(theme.breakpoints.up('lg'))) gap = 30;

  let rowHeight = 60;
  if (useMediaQuery(theme.breakpoints.up('lg'))) rowHeight = 80;



  return (
    <Stack
      sx={{
        marginTop: '100px',
        marginLeft: SPACING.MAIN_LEFT,
        marginRight: SPACING.MAIN_RIGHT,
        marginBottom: { xs: '60px', sm: '100px' },
      }}
    >
      <Typography
        variant="h2"
        sx={{
          paddingBottom: { xs: '0px', sm: '20px' },
        }}
      >
        Our partners & customers
      </Typography>
      <ImageList
        cols={numCols}
        gap={gap}
        rowHeight={rowHeight}
        sx={{
          width: { xs: 300, sm: 600, md: 700, lg: 900, xl: 1600 },
          height: { xs: 220, sm: 150, md: 150, lg: 190, xl: 190 },
        }}
      >
        {logos.map((logo, index) => (
            <ImageListItem key={`${index}`} rows={1} cols={1}>
              <img src={logo.src} alt={logo.name}
                loading="lazy"
                style={{ height: rowHeight, objectFit: 'contain' }}
              />
            </ImageListItem>
          )
        )}
      </ImageList>

    </Stack>
  )
}

export default Partners;
