/**
 * Entry point
 *
 * @returns
 */

import React, { useState, useEffect } from 'react';
import { RouterProvider } from "react-router-dom";
import { SnackbarProvider } from 'notistack';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import { ThemeProvider } from '@mui/material/styles';

import Navigation from './components/navigation/Navigation';
import MobileNavBar from './components/navigation/MobileNavBar';
import Footer from './components/navigation/Footer';

import { SPACING, theme } from './theme.js';
import { router } from './router.js';

function App() {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  // const isMobile = width <= 768;
  const isMobile = width <= 600;  // MaterialUI SM equivalent

  return (
    <SnackbarProvider maxSnack={3}>
      <Stack
        sx={{
          position: 'relative',
          height: '100%',
          width: '100%',
          paddingTop: !isMobile ? '64px' : '81px',
          paddingLeft: !isMobile ? '64px' : '0px',
        }}
      >
        <ThemeProvider theme={theme}>
        {
          isMobile &&
            <MobileNavBar />
        }
        {
          !isMobile &&
            <Navigation />
        }
          <Box
            sx={{
              height: '100%',
              width: '100%',
            }}
          >
            <RouterProvider router={router} />
          </Box>
          <Footer />
        </ThemeProvider>
      </Stack>
    </SnackbarProvider>
  );
}

export default App;
