import React from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link';

import Headline from '../components/blocks/shared/Headline';

import { COLORS, SPACING } from '../theme.js';

const ContactPage = (props) => {

  return (
    <Stack>
      <Headline text="Contact" />
      <Box
        sx={{
          backgroundColor: COLORS.BG_RED,
        }}
      >
        <Typography
          variant="h2"
          sx={{
            color: COLORS.RED,
            paddingLeft: SPACING.MAIN_LEFT,
            lineHeight: '1.5',
            width: '65%',
            marginTop: '70px',
            marginBottom: '70px'
          }}
        >
          You can reach us by email at&nbsp;
          <Link
            href="mailto: info@bendablelabs.com"
            underline="none"
            sx={{
              color: COLORS.RED
            }}
          >
            info@bendablelabs.com
          </Link>
          <br />
           or by phone at 323-896-0626.
        </Typography>
      </Box>
    </Stack>
  )
}

export default ContactPage;
