import React from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import Headline from '../components/blocks/shared/Headline';
import BendableHeroVideo from '../components/blocks/HomePage/BendableHeroVideo';
import BendableLabsHero from '../components/blocks/HomePage/BendableLabsHero';
import About from '../components/blocks/HomePage/About';
import Partners from '../components/blocks/HomePage/Partners';
import Media from '../components/blocks/HomePage/Media';

import HeroVideo from '../images/hero-video-web.mp4';
import HeroVideoGif from '../images/hero-video-mobile.gif';


const HomePage = (props) => {
  return (
    <Stack id="home-page-wrapper">
      {/* ROW 1 - HEADLINE */}
      <Headline />
      {/* ROW 2 - HERO VIDEO */}
      <BendableHeroVideo />
      {/* ROW 3 - ABOUT */}
      <About />
      {/* ROW 4 - SECOND HERO */}
      <BendableLabsHero
        imageUrl="https://bendable.s3.us-west-1.amazonaws.com/phonesWithBendable.webp"
      />
      {/* ROW 5 - PARTNERS & CUSTOMERS */}
      <Partners />
      {/* ROW 6 - MEDIA ARTICLES */}
      <Media />
    </Stack>
  )
}

export default HomePage;
