import React from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link'

import GoIcon from '../../../images/icon_link-arrow.svg';

import { COLORS } from '../../../theme.js';


const MediaGridItem = (props) => {
  let {
    article = {
      date: '',
      source: '',
      title: '',
      url: ''
    }
  } = props;
  return (
    <Grid item xs={12} sm={8} md={4} lg={4} xl={4}>
      <Link href={article.url} underline="none" target="_blank">
        <Box
          className="media-grid-item"
          sx={{
            border: `6px solid ${COLORS.BG_DARKBLUE}`,
            height: {xs: "auto", sm: "100%"},
            padding: "25px",
            "&:hover": {
              borderTopLeftRadius: "30px 30px",
              border: `12px solid ${COLORS.BG_DARKBLUE}`,
              padding: "16px"
            }
          }}
        >
          <Typography variant="overline"
            sx={{
              color: COLORS.BLUE,
              paddingBottom: "5px",
              fontSize: { xs: '.7rem', sm: '1rem', md: '.7rem', lg: '1rem', xl: '1.1rem' }
            }}
          >
            {article.date} | {article.source}
          </Typography>
          <Typography variant="h6"
            sx={{
              color: COLORS.BG_DARKBLUE,
              paddingBottom: "5px",
              fontSize: { xs: '1.1rem', sm: '1.7rem', md: '1rem', lg: '1.7rem', xl: '1.8rem' }
            }}
          >
            {article.title}
          </Typography>
          <Box>
            <img className="media-link-arrow" src={GoIcon} alt-text="Go to link" />
          </Box>
        </Box>
      </Link>
    </Grid>
  )
}

export default MediaGridItem;
