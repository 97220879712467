import React from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';

import { COLORS, SPACING } from '../../../theme.js';

import LifelongLearning from '../../../images/image_lifelong-learning.svg';
import WorkforceDevelopment from '../../../images/image_workforce-development.svg';
import JobQuality from '../../../images/image_job-quality.svg';

const About = (props) => {
  return (
    <Grid container
      sx={{
        background: "#F5F5F5",
        paddingTop: '64px',
        paddingLeft: SPACING.MAIN_LEFT,
        paddingRight: SPACING.MAIN_RIGHT,
        paddingBottom: '64px'
      }}
    >
      <Grid item xs={12} sm={12} md={8}>
        <Box
          sx={{
            paddingRight: {xs: '0px', sm: '50px'},
          }}
        >
          <Typography
            variant="h2"
            sx={{
              color: COLORS.BLUE,
              paddingBottom: '21px',
              lineHeight: { xs:'1.9rem', sm: '2.2rem', md: '2.5rem', lg: '3.3rem' },
            }}
          >
            Bendable Labs is a technology, consulting and research
            firm that aims to improve economic stability, mobility
            and opportunity for people across the United States.
          </Typography>
          <Typography
            variant="body1"
            sx={{
              lineHeight: { xs: '1.7rem', sm: '1.9rem', md: '2.2rem', lg: '2.8rem' },
            }}
          >
            Spun out of the Drucker Institute at Claremont Graduate
            University, we design, build and test social innovations
            in the areas of lifelong learning, workforce development
            and job quality.
          </Typography>
        </Box>

      </Grid>
      <Grid item xs={12} sm={12} md={4}
        sx={{
          paddingTop: {xs: '20px', xl: '0px'},
          paddingLeft: '20px',
          display: 'flex',
          alignContent: 'center',
          flexDirection: {xs: 'row', sm: 'row', md: 'row', lg: 'column'},
          justifyContent: 'center'
        }}
      >
        <Stack direction={{xs: 'column', sm: 'row', md: 'column'}} >

          <Box
            sx={{
              display: "flex",
              flexDirection: {xs: "column", sm: "row"},
              alignItems: "center",
              padding: "5px",
              marginTop: {xs: '10px', sm: '10px', md: '0px'},
              marginBottom: {xs: '0px', md: '20px'},
            }}
          >
            <Box
              sx={{
                display: "flex",
                marginRight: "10px"
              }}
            >
              <img
                src={LifelongLearning}
                className="footer-logo__image"
                alt="bendable logo"
              />

            </Box>
            <Typography variant="caption" sx={{
                marginRight: "40px"
              }}
            >
              Lifelong<br/>learning
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: {xs: "column", sm: "row"},
              alignItems: "center",
              padding: "5px",
              marginTop: {xs: '10px', sm: '10px', md: '0px'},
              marginBottom: {xs: '0px', md: '20px'},
            }}
          >
            <Box
              sx={{
                display: "flex",
                marginRight: "10px"
              }}
            >
              <img
                src={WorkforceDevelopment}
                className="footer-logo__image"
                alt="bendable logo"
              />

            </Box>
            <Typography variant="caption" sx={{
                marginRight: "40px"
              }}>
              Workforce<br/>development
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: {xs: "column", sm: "row"},
              alignItems: "center",
              padding: "5px",
              marginTop: {xs: '10px', sm: '10px', md: '0px'}
            }}
          >
            <Box
              sx={{
                display: "flex",
                marginRight: {xs: "5px", md: "10px"}
              }}
            >
              <img
                src={JobQuality}
                className="footer-logo__image"
                alt="bendable logo"
              />

            </Box>
            <Typography variant="caption" sx={{
                marginRight: "40px"
              }}>
              Job<br/>quality
            </Typography>
          </Box>

        </Stack>

      </Grid>
    </Grid>
  )
}

export default About;
