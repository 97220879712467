import React from 'react';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'

import Headline from '../components/blocks/shared/Headline';
import LaptopLearner from '../images/laptop_learner.png';
import Hfll22 from '../images/hfll22.png';
import JrrScreen from '../images/jrr_screen.png';
import DeiLeader from '../images/dei_leader.png';

import { COLORS, SPACING } from '../theme.js';

const SocialPurposeAnnualReportPage = (props) => {

  return (
    <Stack>
      <Headline text="2023 annual report" />
      <Stack
        sx={{
          paddingLeft: SPACING.MAIN_LEFT,
          paddingRight: { xs: '40px', sm: '80px', md: '120px', lg: '160px', xl: '200px' },
          lineHeight: '1.5'
        }}
      >
        <Divider
          sx={{
            marginBottom: { xs: '10px', sm: '10px', md: '20px', lg: '30px', xl: '30px' },
          }}
        />

        <Typography
          sx={{
            fontFamily: 'Sharp Sans',
            fontSize: '1.0rem',
            fontColor: '#000000',
            marginBottom: '20px',
          }}
        >
          <div style={{
            float: 'left',
            fontSize: '4.6rem',
            lineHeight: '3rem',
            paddingTop: '15px',
            paddingRight: '4px'
            }}>B</div>endable Labs is registered with the State of California as a&nbsp;
          <Link
            href="http://www.leginfo.ca.gov/pub/13-14/bill/sen/sb_1301-1350/sb_1301_cfa_20140621_181747_asm_comm.html"
            underline="none"
            target="_blank"
            rel="noreferrer"
            sx={{
              color: '#297687',
            }}
          >
            social purpose corporation
          </Link>, a designation intended to give companies greater flexibility to prioritize pursuing the public 
          good in addition to generating profits.
        </Typography>

        <Typography
          sx={{
            fontFamily: 'Sharp Sans',
            fontSize: '1.0rem',
            fontColor: '#000000',
            marginBottom: '20px',
          }}
        >
          The year 2023 marked our first in operation since spinning out of the Drucker Institute, a social enterprise at 
          Claremont Graduate University. One of our aims in becoming a social purpose corporation was to better position 
          ourselves to scale the initiatives we had launched in a university setting.
        </Typography>

        <Stack
          sx={{
            flexDirection: { xs: 'column', sm: 'column', md: 'column', lg: 'row', xl: 'row' }
          }}
        >
          <Typography
            component="div"
            sx={{
              fontFamily: 'Sharp Sans',
              fontSize: '1.0rem',
              fontColor: '#000000',
              marginBottom: '20px',
            }}
          >
            <Box
              component="img"
              sx={{
                width: { xs: '290px', md: '446px', },
                height: { xs: '195px', md: '300px', },
                float: 'right',
                marginLeft: { xs: '10px', sm: '20px', },
                marginBottom: { xs: '20px', sm: '10px' },
              }}
              alt="Learner using a laptop"
              src={LaptopLearner}
            />

            To that end, during the course of the year we began to cultivate new markets for Bendable, our lifelong learning 
            and workforce development platform. While we continue to offer Bendable to public libraries, so that their 
            patrons can easily discover and access the learning that they need to make strides in their careers and thrive 
            outside of work, we realized that we can have even more impact by making Bendable available in other trusted spaces.

            <hr style={{ height: '0rem', border: '0px solid white' }}/>

            We piloted this concept during the spring at the parent center at Sal Castro Middle School in Los Angeles. Once 
            center-goers signed up for a Bendable account, they were able to find free learning content in areas that 
            matter to them most: work skills, personal technology, financial literacy, well-being and effective parenting.
          </Typography>
        </Stack>

        <Typography
          sx={{
            fontFamily: 'Sharp Sans',
            fontSize: '1.0rem',
            fontColor: '#000000',
            marginBottom: '20px',
          }}
        >
          Although users can access Bendable on any device at any time, we are mindful that learning is social. And so part 
          of our offering now includes discussion guides that can be used to run workshops on a variety of topics, allowing 
          participants to learn with each other and from each other. At Sal Castro, for instance, it was wonderful to see 
          school staff lead a lively and informative conversation after parents went on Bendable together to watch a short 
          video about helping children cope with social anxiety.
        </Typography>

        <Typography
          sx={{
            fontFamily: 'Sharp Sans',
            fontSize: '1.0rem',
            fontColor: '#000000',
            marginBottom: '20px',
          }}
        >
          For schools, Bendable is a powerful way to engage parents and pull them onto campus, which in turn helps to drive 
          student outcomes. Indeed, a large body of research has found that parents’ involvement at school leads to increased 
          achievement, positive attitudes and persistence to graduation by their children.
        </Typography>

        <Typography
          sx={{
            fontFamily: 'Sharp Sans',
            fontSize: '1.0rem',
            fontColor: '#000000',
            marginBottom: '20px',
          }}
        >
          More broadly, we know that lifelong learning is good for mental well-being and self-esteem, can help people gain 
          practical skills and can connect them to like-minded individuals who share a passion. For seniors, research 
          has shown that lifelong learning helps to prevent the onset of dementia, sharpens alertness, stimulates new 
          neural connections, promotes socialization and improves memory function and cognition.
        </Typography>

        <Typography
          sx={{
            fontFamily: 'Sharp Sans',
            fontSize: '1.0rem',
            fontColor: '#000000',
            marginBottom: '20px',
          }}
        >
          By late 2023, following our successful pilot at Sal Castro, we began developing relationships with other schools 
          in multiple districts, and we have since been in discussions with public and nonprofit job centers, youth centers, 
          family centers and senior living communities about how Bendable can help meet the needs of those they serve. We 
          look forward to Bendable expanding into all of these new contexts in 2024.
        </Typography>




        <Stack
          sx={{
            flexDirection: { xs: 'column', sm: 'column', md: 'column', lg: 'row', xl: 'row' }
          }}
        >
          <Typography
            component="div"
            sx={{
              fontFamily: 'Sharp Sans',
              fontSize: '1.0rem',
              fontColor: '#000000',
              marginBottom: '20px',
            }}
          >
            <Box
              component="img"
              sx={{
                width: { xs: '290px', md: '453px', },
                height: { xs: '192px', md: '300px', },
                float: 'left',
                marginRight: { xs: '10px', sm: '20px', },
                marginBottom: { xs: '20px', sm: '10px' },
              }}
              alt="HFLL 22 group photo"
              src={Hfll22}
            />
            We also continued our school-related work in 2023 through the Harbor Freight Leadership Lab, which brings together 
            individuals from across the nation’s K-12 skilled trades education ecosystem. During the year, HFLL’s 22 
            participants—the third and final cohort in a three-year pilot—met online and in person to acquire practical 
            management and leadership tools that can help boost their careers, all with an eye on elevating the skilled 
            trade sector’s performance, prestige and standing in society.       

            <hr style={{ height: '0rem', border: '0px solid white' }}/>

            A third-party evaluation found that the lab deepened participants’ “enthusiasm for their work,” 
            promoted “out-of-the-box thinking,” “shaped transformational ideas” and “played a vital role in 
            helping participants navigate the STE landscape, offering valuable advice...and connections to key resources.”
          </Typography>

        </Stack>



        <Typography
          sx={{
            fontFamily: 'Sharp Sans',
            fontSize: '1.0rem',
            fontColor: '#000000',
            marginBottom: '20px',
          }}
        >
          Late in 2023, with the pilot complete, we started planning for a fourth HFLL cohort and began envisioning with 
          our partners at Harbor Freight Tools for Schools and the Smidt Foundation what the next iteration of the lab will look like.
        </Typography>

        <Typography
          sx={{
            fontFamily: 'Sharp Sans',
            fontSize: '1.0rem',
            fontColor: '#000000',
            marginBottom: '20px',
          }}
        >
          Also on the K-12 front, we worked with Menlo Education Research to design and develop an organizational and knowledge-sharing 
          structure that would engage members of the Advancing Innovative Math Solutions (AIMS) Collaboratory. This community of 
          practice is composed of cross-sector teams committed to lifting up mathematics education, particularly for students of 
          color and students affected by poverty. The project, which included 57 organizations in 2023, is funded by the 
          Bill & Melinda Gates Foundation.
        </Typography>






        <Stack
          sx={{
            flexDirection: { xs: 'column', sm: 'column', md: 'column', lg: 'row', xl: 'row' }
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Sharp Sans',
              fontSize: '1.0rem',
              fontColor: '#000000',
              marginBottom: '20px',
            }}
          >
            <Box
              component="img"
              sx={{
                width: { xs: '295px', md: '446px', },
                height: { xs: '151px', md: '228px', },
                float: 'left',
                marginRight: { xs: '10px', sm: '20px', },
                marginBottom: { xs: '20px', sm: '10px' },
              }}
              alt="Job Readiness Room homepage"
              src={JrrScreen}
            />
            In June, we wrapped up a 10-month pilot of the Job-Readiness Room, a library-based workforce development program 
            that we designed and implemented in north San Diego County with the labor market analytics firm Lightcast and 
            the City of Carlsbad’s Department of Innovation & Economic Development. More than 150 individuals signed up for 
            the JRR, which provided 15 hours of entry-level job-readiness training through an online platform made available 
            by the Carlsbad City Library.                    
          </Typography>
        </Stack>



        <Typography
          sx={{
            fontFamily: 'Sharp Sans',
            fontSize: '1.0rem',
            fontColor: '#000000',
            marginBottom: '20px',
          }}
        >
          Although we and our partners decided not to extend the program beyond the pilot stage—primarily due to a lack of firm 
          commitment by local employers—we learned many lessons that have informed our thinking around the role of public libraries 
          in workforce development and that will be valuable as we pursue new opportunities in this area. Among these are the need 
          for job seekers to be fully supported by learning coaches and career navigators during their journey, and for local 
          hiring managers to co-design any skills-based hiring system so that it truly meets their needs.
        </Typography>

        


        <Stack
          sx={{
            flexDirection: { xs: 'column', sm: 'column', md: 'column', lg: 'row', xl: 'row' }
          }}
        >
          <Typography
            component="div"
            sx={{
              fontFamily: 'Sharp Sans',
              fontSize: '1.0rem',
              fontColor: '#000000',
              marginBottom: '20px',
            }}
          >
            <Box
              component="img"
              sx={{
                width: { xs: '290px', md: '457px', },
                height: { xs: '206px', md: '324px', },
                float: 'right',
                marginLeft: { xs: '10px', sm: '20px', },
                marginBottom: { xs: '20px', sm: '10px' },
              }}
              alt="DEI article thumbnail"
              src={DeiLeader}
            />
            Finally, we worked with the Drucker Institute to perform and interpret the calculations that make up its annual 
            company rankings and serve as the basis of <i>The Wall Street Journal’</i>s “Management Top 250” list—all part of an 
            effort to encourage major U.S. corporations to move away from putting the interests of their shareholders ahead of 
            those of their workers and other stakeholders. Tech companies dominated the 2023 rankings, with Microsoft at 
            No. 1, followed by Apple, Nvidia, Alphabet and IBM.

            <hr style={{ height: '0rem', border: '0px solid white' }}/>

            As part of our work, we published several stories in the <i>Journal</i> throughout the year, including a look at how 
            companies that had an executive devoted to diversity, equity and inclusion scored higher in the rankings than 
            did their peers without a DEI officer.
          </Typography>
        </Stack>



        <Typography
          sx={{
            fontFamily: 'Sharp Sans',
            fontSize: '1.0rem',
            fontColor: '#000000',
            marginBottom: '60px',
          }}
        >
          All of our operating expenses for 2023—$1.9 million—went toward furthering our social purpose.
        </Typography>

      </Stack>
    </Stack>
  );
}

export default SocialPurposeAnnualReportPage;
