import React, { useState } from 'react';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';

import ConfirmationDialog from '../shared/ConfirmationDialog';

const MediaItem = (props) => {

  const [isDirty, setIsDirty] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const {
    data,
    onDataChange = () => {},
    onSave = () => {},
    onDelete = () => {},
  } = props;

  const handleChange = (updateData) => {
    updateData._id = data._id;
    console.log('MediaItem.handleChange() updateData: ', updateData);
    onDataChange(updateData);
    setIsDirty(true);
  };

  const triggerConfirmDelete = () => {
    setShowDeleteConfirmation(true);
  };

  const handleConfirmDelete = (action) => {
    console.log('MediaItem.handleConfirmDelete() action: ', action);
    setShowDeleteConfirmation(false);
    onDelete({ _id: data._id });
  };

  const isNewItem = !data._id;

  return (
    <Card
      sx={{
        padding: '20px',
        marginBottom: '10px',
      }}
    >
      <Stack direction="column">

        <Stack direction="row">
          <TextField
            size="small"
            label="Date (m.d.yyyy)"
            variant="outlined"
            value={data.date}
            tabIndex="0"
            onChange={(event) => { handleChange({ field: 'date', value: event.target.value }); }}
            sx={{
              width: '400px',
              marginBottom: '10px',
            }}
          />

          <Button
            variant='contained'
            disabled={!isDirty}
            tabIndex="4"
            onClick={(event) => { onSave({ _id: data._id })}}
            sx={{
              backgroundColor: '#008AAF',
              borderRadius: '20px',
              height: '40px',
              width: '200px',
              marginTop: '5px',
              marginLeft: '30px',
            }}
          >
            { isNewItem ? 'Create Item' : 'Save Changes' }
          </Button>

          <Button
            variant='contained'
            tabIndex="5"
            onClick={(event) => { triggerConfirmDelete(); }}
            sx={{
              display: isNewItem ? 'none' : 'block',
              backgroundColor: '#A00108',
              borderRadius: '20px',
              height: '40px',
              width: '200px',
              marginTop: '5px',
              marginLeft: '30px',
            }}
          >
            Delete Item
          </Button>


        </Stack>

        <TextField
          size="small"
          label="Source"
          variant="outlined"
          value={data.source}
          tabIndex="1"
          onChange={(event) => { handleChange({ field: 'source', value: event.target.value }); }}
          sx={{
            width: '100%',
            marginBottom: '10px',
          }}
        />

        <TextField
          size="small"
          label="Title"
          variant="outlined"
          value={data.title}
          tabIndex="2"
          onChange={(event) => { handleChange({ field: 'title', value: event.target.value }); }}
          sx={{
            width: '100%',
            marginBottom: '10px',
          }}
        />

        <TextField
          size="small"
          label="URL"
          variant="outlined"
          value={data.url}
          tabIndex="3"
          onChange={(event) => { handleChange({ field: 'url', value: event.target.value }); }}
          sx={{
            width: '100%',
            marginBottom: '10px',
          }}
        />



      </Stack>





      <ConfirmationDialog
        shouldOpen={showDeleteConfirmation}
        confirmationText="Are you sure you wish to permanently delete this item? Recovery is not possible."
        confirmButton="Delete"
        onClose={handleConfirmDelete}
      />


    </Card>
  );
};

export default MediaItem;