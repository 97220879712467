import { createTheme } from '@mui/material/styles';

export const COLORS = {
  BLACK: '#303836',
  BLUE: '#297687',
  RED: '#822123',
  BG_LIGHTBLUE: '#8ED6E8',
  BG_DARKBLUE: '#008AAF',
  BG_LIGHTGOLD: '#E9AD21',
  BG_DARKGOLD: '#E7B33F',
  BG_RED: '#EC7F77'
}

export const SPACING = {
  MAIN_LEFT: { xs:'35px', sm:'35px', md:'140px' },
  MAIN_RIGHT: { xs:'35px', sm:'35px', md:'140px' },
  MAIN_TOP: {xs: '64px'},
  MAIN_BOTTOM: {xs: '64px'},
}

export const MOBILE_WIDTH = 600;

export const theme = createTheme({
  typography: {
    fontFamily: 'Sharp Sans',
  },
});

theme.typography.h1 = {
  fontFamily: 'CooperBTMedium',
  color: COLORS.BLACK,
  fontStyle: 'normal',
  fontWeight: 600,
  paddingTop: '64px',
  paddingBottom: '64px',

  // In Designs: 52px
  // Normal Laptop
  [theme.breakpoints.up('lg')]: {
    fontSize: '3.8rem',
  },
  // Large Tablet
  [theme.breakpoints.down('lg')]: {
    fontSize: '2.4rem'
  },
  // Tablet
  [theme.breakpoints.down('md')]: {
    fontSize: '2rem'
  },
  // Mobile
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.6rem',
  },
};

theme.typography.h2 = {
  fontFamily: 'CooperBTMedium',
  color: COLORS.BLACK,
  fontStyle: 'normal',
  fontWeight: 600,

  // In Designs: 44px
  [theme.breakpoints.up('lg')]: {
    fontSize: '2.5rem',
  },
  // Small Laptop
  [theme.breakpoints.down('lg')]: {
    fontSize: '1.75rem'
  },
  // Large Tablet
  [theme.breakpoints.down('md')]: {
    fontSize: '1.63rem'
  },
  // Tablet
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.25rem',
  },
};

theme.typography.h4 = {
  fontFamily: 'CooperBTMedium',
  color: COLORS.RED,
  fontStyle: 'normal',
  fontWeight: 400,

  // In Designs: 36px
  // Small Laptop
  [theme.breakpoints.down('lg')]: {
    fontSize: '1.6rem'
  },
  // Large Tablet
  [theme.breakpoints.down('md')]: {
    fontSize: '1.4rem'
  },
  // Tablet
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.2rem',
  },
}

theme.typography.h5 = {
  fontFamily: 'CooperBTMedium',
  color: 'white',
  fontStyle: 'normal',
  fontWeight: 400,

  // In Designs: 32px
  // Small Laptop
  [theme.breakpoints.down('lg')]: {
    fontSize: '1.4rem'
  },
  // Large Tablet
  [theme.breakpoints.down('md')]: {
    fontSize: '1.2rem'
  },
  // Tablet
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
  },
}

theme.typography.h6 = {
  fontFamily: 'CooperBTMedium',
  fontStyle: 'normal',
  fontWeight: 500,

  // In Designs: 26px
  // Small Laptop
  [theme.breakpoints.down('lg')]: {
    fontSize: '1.2rem'
  },
  // Large Tablet
  [theme.breakpoints.down('md')]: {
    fontSize: '1rem'
  },
  // Tablet
  [theme.breakpoints.down('sm')]: {
    fontSize: '.8rem',
  },
}

theme.typography.body1 = {
  fontFamily: "Sharp Sans",
  fontStyle: 'normal',
  color: COLORS.BLACK,
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.5rem',
  },
  // Small Laptop
  [theme.breakpoints.down('lg')]: {
    fontSize: '1.2rem'
  },
  // Large Tablet
  [theme.breakpoints.down('md')]: {
    fontSize: '1rem'
  },
  // Tablet
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
  },
}

theme.typography.caption = {
  fontFamily: "Sharp Sans",
  fontStyle: 'normal',
  color: COLORS.BLACK,
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.2rem',
  },
  // Small Laptop
  [theme.breakpoints.down('lg')]: {
    fontSize: '1rem'
  },
  // Large Tablet
  [theme.breakpoints.down('md')]: {
    fontSize: '.8rem'
  },
  // Tablet
  [theme.breakpoints.down('sm')]: {
    fontSize: '.6rem',
  },
}

theme.typography.subtitle1 = {
  fontFamily: "Sharp Sans",
  fontStyle: 'normal',
  fontWeight: '600',
  color: COLORS.BLACK,
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.2rem',
  },
  // Small Laptop
  [theme.breakpoints.down('lg')]: {
    fontSize: '1.1rem'
  },
  // Large Tablet
  [theme.breakpoints.down('md')]: {
    fontSize: '.9rem'
  },
  // Tablet
  [theme.breakpoints.down('sm')]: {
    fontSize: '.7rem',
  },
}

theme.typography.subtitle2 = {
  fontFamily: "Sharp Sans",
  fontStyle: 'normal',
  fontWeight: '500',
  color: COLORS.RED,
  textTransform: "uppercase",

  // In Designs: 26px
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.1rem',
  },
  // Small Laptop
  [theme.breakpoints.down('lg')]: {
    fontSize: '1rem'
  },
  // Large Tablet
  [theme.breakpoints.down('md')]: {
    fontSize: '.8rem'
  },
  // Tablet
  [theme.breakpoints.down('sm')]: {
    fontSize: '.7rem',
  },
}

theme.typography.overline = {
  fontFamily: "Sharp Sans",
  fontStyle: 'normal',
  fontWeight: '500',

  // In Designs: 16px
  [theme.breakpoints.up('lg')]: {
    fontSize: '.8rem',
  },
  // Small Laptop
  [theme.breakpoints.down('lg')]: {
    fontSize: '.7rem'
  },
  // Large Tablet
  [theme.breakpoints.down('md')]: {
    fontSize: '.6rem'
  },
  // Tablet
  [theme.breakpoints.down('sm')]: {
    fontSize: '.5rem',
  },
}
