import React from 'react';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import WhiteLogo from '../../images/bendable-labs-logo_white.svg';

import { SPACING } from '../../theme.js'

import './Navigation.css';

const NavigationMenuItems = (props) => {
  let {
    open = false,
    isMobile = false
  } = props;


  const menuItems = [
    {
      text: 'Who we are',
      url: '/who-we-are'
    },
    {
      text: 'What we believe',
      url: '/what-we-believe'
    },
    {
      text: 'Our programs and products',
      url: '/programs-and-products'
    },
    {
      text: 'Perspective',
      url: '/perspective'
    },
    {
      text: 'Social purpose annual report',
      url: '/social-purpose-annual-report'
    },
    {
      text: 'Contact',
      url: '/contact'
    },
  ];

  return (
    <Stack direction="column"
      sx={{
        marginLeft: !isMobile ? '64px' : '0px' // Use breakpoints instead?
      }}
    >
      <Box
        sx={{
          height: { xs: '32px' , md: '64px' },
          flexGrow: 1,
          marginLeft: SPACING.MAIN_LEFT,
          marginRight: SPACING.MAIN_LEFT,
          marginBottom: '10px',
          marginTop: !isMobile ? '64px' : '30px', // Use breakpoints instead?
          ...(!open && { display: 'none' }),
        }}
      >
        <Link href="/" alt="Go to Home Page">
          <img
            src={WhiteLogo}
            height="100%"
            alt="bendable logo"
          />
        </Link>
      </Box>

      <List
        sx={{
          flexGrow: 1,
          marginLeft: SPACING.MAIN_LEFT,
          marginRight: SPACING.MAIN_LEFT,
        }}
      >
        {menuItems.map((item, index) => (
          <ListItem key={item.text} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              component="a"
              href={item.url}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                padding: 0,
              }}
            >
              <ListItemText
                primary={item.text}
                primaryTypographyProps={{
                  fontFamily: 'CooperBTMedium',
                  color: 'white',
                  fontSize: {xs: '20px', sm: '28px', md: '36px'}
                }}
                sx={{
                  opacity: open ? 1 : 0,
                }} />
            </ListItemButton>
            {
              index !== menuItems.length - 1 &&
                <Divider sx={{
                    width: '100%',
                    marginTop: '10px',
                    marginBottom: '10px',
                    backgroundColor: 'white'
                  }}
                />
            }
          </ListItem>
        ))}
      </List>

      {/* <Stack
        sx={{
          background: '#008AAF',
          color: 'white',
          paddingTop: '30px',
          paddingLeft: SPACING.MAIN_LEFT,
          paddingRight: SPACING.MAIN_RIGHT,
          paddingBottom: '30px',
          fontFamily: 'CooperBTMedium',
          bottom: 0,
          position: 'absolute',
          width: '100%'
        }}
      >
        <Stack
          direction='row'
          sx={{
            marginBottom: '30px'
          }}
        >
          <Box sx={{ flexGrow: '1' }}>
            <Link
              href="mailto: info@bendablelabs.com"
              underline='none'
              sx={{
                color: 'white',
                width: '100%'
              }}
            >
              info@bendablelabs.com
            </Link>
          </Box>
          <Box sx={{ flexGrow: '1' }}>
            323-896-0626
          </Box>
        </Stack>

        <Divider sx={{ backgroundColor: 'white' }} />

        <Box
          sx={{
            marginTop: '30px',
          }}
        >
          Site design: Kilter
        </Box>
      </Stack> */}


    </Stack>
  )
}

export default NavigationMenuItems;
