import React from 'react';

import Stack from '@mui/material/Stack';

import Headline from '../components/blocks/shared/Headline';
import EmployeeGrid from '../components/blocks/WhoWeArePage/EmployeeGrid';

const WhoWeArePage = (props) => {

  return (
    <Stack>
      <Headline text="Who we are" />
      <EmployeeGrid />
    </Stack>
  )
}

export default WhoWeArePage;
