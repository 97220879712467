import React, { useEffect, useState } from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import ImageList from '@mui/material/ImageList';

import EmployeeImage from '../shared/EmployeeImage';

import { COLORS, SPACING, MOBILE_WIDTH } from '../../../theme.js';

import employeeList from '../../../data/employees.json';

const EmployeeGrid = (props) => {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);  
  const isMobile = width <= MOBILE_WIDTH;  // MaterialUI SM equivalent
  const numCols = !isMobile ? 3 : 1;

  return (
    <Stack
      sx={{
        backgroundColor: COLORS.BG_RED,
        paddingTop: {xs: '64px'},
        paddingBottom: {xs: '64px'},
        paddingLeft: SPACING.MAIN_LEFT,
        paddingRight: SPACING.MAIN_RIGHT,
        textAlign: "center"
      }}
    >
      <ImageList className="customer-logo-list" cols={numCols} rowHeight='auto' gap={20}>
        {employeeList.map((employee, index) => (
            <Link href={`/who-we-are/${employee.employeeId}`} underline="none" key={`${index}`}
              sx={{
                marginBottom: { xs: '10px', sm: '30px' }
              }}
            >
              <EmployeeImage employee={employee.employeeId} inGrid={true} />
              <Typography 
                variant="h4"
                sx={{
                  paddingTop: '10px',
                  paddingBottom: '10px'
                }}
              >
                {employee.name}
              </Typography>
              <Typography variant="subtitle2">{employee.title}</Typography>
            </Link>
          )
        )}
      </ImageList>
    </Stack>
  )
}

export default EmployeeGrid;
