import React from 'react';

import ImageListItem from '@mui/material/ImageListItem'

import Jamie from '../../../images/portrait_jamie-jones.jpeg'
import Kelly from '../../../images/portrait_kelly-tang.png'
import Phalana from '../../../images/portrait_phalana-tiller.png'
import Rick from '../../../images/portrait_rick-wartzman.png'
import Layna from '../../../images/portrait_layna-williams.png'
import Dan from '../../../images/portrait_dan-zaner.png'
import Sarah from '../../../images/portrait_sarah-zaner.png'

import './EmployeeImage.css';

const peopleListImages = {
  "jamie-jones": Jamie,
  "kelly-tang": Kelly,
  "phalana-tiller": Phalana,
  "rick-wartzman": Rick,
  "layna-williams": Layna,
  "dan-zaner": Dan,
  "sarah-zaner": Sarah
}

const EmployeeImage = (props) => {
  const {
    employee = 'not-found',
    inGrid = 'true'
  } = props;

  return (
    <ImageListItem rows={1} cols={1}
      sx={{
      }}
    >
      <img 
        className={inGrid ? "employee-grid-image" : "employee-profile-image"} 
        src={peopleListImages[employee]} alt={employee}
        loading="lazy"
      />
    </ImageListItem>
  )
}

export default EmployeeImage;
