import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import ButtonBase from '@mui/material/ButtonBase';

import NavigationMenuItems from './NavigationMenuItems';

import './Navigation.css';

const MobileNavBar = (props) => {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (event) => {
    setOpen(!open);
  };

  return (
    <Stack
      id="primary-app-bar-wrapper"
      component="header"
      direction="row"
      sx={{
        position: 'fixed',
        top: 0,
        width: '100%',
        background: '#FFFFFF',
        paddingTop: '0.625rem',
        paddingBottom: '0.625rem',
        paddingLeft: { xs: '1.5rem' , sm: '1.8rem', md: '3rem', lg: '4rem' },
        paddingRight: { xs: '1.5rem' , sm: '1.8rem', md: '3rem', lg: '4rem' },
        zIndex: 10,
        alignItems: 'center',
        boxSizing: 'border-box',
      }}
    >
      <Box
        className="primary-app-bar__logo"
        sx={{
          height: { xs: '32px' , md: '64px' },
          flexGrow: 1
        }}
      >
        <a href="/" alt-text="Go to home page">
          <img src="https://bendable.s3.us-west-1.amazonaws.com/side-by-side-logo.png" height="100%" alt="bendable logo" />
        </a>
      </Box>
      <Box
          aria-label="open drawer on mobile"
          onClick={toggleDrawer}
          sx={{ fontSize: "2rem" }}
        >
          <span>&#x2630;</span>
      </Box>


      <Drawer
        className={open ? "nav-drawer-open" : "nav-drawer-closed"}
        variant="temporary"
        open={open}
        anchor="right"
      >
      {
        open &&
        <Box
          sx={{
            width: '64px',
            position: 'absolute',
            top: 0,
            right: 0,
            zIndex: 10,
            paddingLeft: '32px'
          }}
        >
          <ButtonBase
            onClick={toggleDrawer}
            sx={{
              fontFamily: 'Sharp Sans',
              fontSize: '2rem',
              fontWeight: 700,
              height: '64px',
              marginTop: '1rem',
              color: 'white',
              ...(!open && { display: 'none' }),
            }}
          >
            <span>&#10005;</span>
          </ButtonBase>
        </Box>
      }
      <NavigationMenuItems open={open} isMobile={true} />
      </Drawer>

    </Stack>
  );
};

export default MobileNavBar;
