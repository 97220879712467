/**
 * API Service: Client for Bendable2 REST API
 * 
 * 
 */

import axios from 'axios';
// import fileDownload from 'js-file-download';
import _ from 'lodash-es';

console.log('apiService.js location: ', document.location);

const apiHostname = _.isString(process.env.REACT_APP_API_HOSTNAME) ? process.env.REACT_APP_API_HOSTNAME.trim() : 'bendable2-api.bendable.com';
const apiPort = _.isString(process.env.REACT_APP_API_PORT) ? `:${process.env.REACT_APP_API_PORT.trim()}` : '';
const apiVersion = _.isString(process.env.REACT_APP_API_VERSION) ? process.env.REACT_APP_API_VERSION.trim() : 'v2';

// let apiBaseUrl = `https://${document.location.hostname}${apiPort}/`;
let apiBaseUrl = `https://${apiHostname}${apiPort}/`;
console.log('apiService apiBaseUrl: ', apiBaseUrl);
if (!apiBaseUrl) apiBaseUrl = 'misconfiguredApiService';
console.log('apiService apiVersion: ', apiVersion);


export const getBaseUrl = () => {
  return apiBaseUrl;
};

export const urlEncode = (url) => {
  return encodeURIComponent(url);
};

let verboseLogging = true;

const callDefinitions = {
  getBlMedia: {
    pathTemplate: '/blMedia',
    method: 'get'
  },
  upsertBlMedia: {
    pathTemplate: '/blMedia',
    method: 'post'
  },
  deleteBlMedia: {
    pathTemplate: '/blMedia',
    method: 'delete'
  },

};



/**
* Generalized API call
*
* @param  {[type]}   apiKey             [description]
* @param  {[type]}   substitutionParams [description]
* @param  {[type]}   axiosOptions       [description]
* @param  {Function} callback           [description]
* @return {[type]}                      [description]
*
* Returned object is augmented with the original _apiKey, _substitutionParams, 
* and _jsonData
*/
const callApi = async (apiKey, substitutionParams, axiosOptions) => {
  substitutionParams = substitutionParams || {};
  axiosOptions = axiosOptions || {};

  // get the call definition
  let callDefinition = callDefinitions[apiKey];
  if (!callDefinition) {
    console.log('ERROR: ApiService.callApi() no api call for apiKey: ', apiKey);
    throw new Error('No API call found for ' + apiKey);
  }

  if (!substitutionParams.apiVersion) substitutionParams.apiVersion = callDefinition.apiVersion;    // default for call
  if (!substitutionParams.apiVersion) substitutionParams.apiVersion = apiVersion;                   // from .env

  let pathTemplate = apiBaseUrl + `${substitutionParams.apiVersion}` + callDefinition.pathTemplate;
  let regex = /(?:^|\W):(\w+)(?!\w)/g;
  let regexTokens = [];
  let tempArray;
  while ((tempArray = regex.exec(pathTemplate)) !== null) {
    regexTokens.push(tempArray[1]);
  }

  // sanitize substitutionParams
  _.forOwn(substitutionParams, function(value, key, obj) {
    // substitutionParams values must be strings
    if (_.isNumber(value)) {
      obj[key] = '' + value;
    }
    // values with strings with must be URLencoded or we won't match routes when /, etc is present
    if (_.isString(value)) {
      obj[key] = encodeURIComponent(value);
    }
  });

  console.log('apiService.callApi() substitutionParams: ', substitutionParams);

  let path = pathTemplate;
  for (const regexToken of regexTokens) {
    console.log('apiService.callApi() regexToken: ', regexToken);
    if (!substitutionParams[regexToken]) {
      console.log('apiService.callApi() error; missing required path regexToken for apiKey, regexToken: ', apiKey, regexToken);
      throw new Error('Missing required path regexToken for ' + apiKey + ' (' + regexToken + ')');
    }
    path = path.replace((':' + regexToken), substitutionParams[regexToken]);
  }
  console.log(`callApi() ${callDefinition?.method} path: ${path}`);

  // move axiosOptions to { data: axiosOptions } if POST or PUT and no 'data' field present
  if ((callDefinition.method === 'post') || (callDefinition.method === 'put')) {
    if (!axiosOptions.data) {
      axiosOptions = {
        data: axiosOptions
      };
    }
  }

  axiosOptions.url = path;
  axiosOptions.method = callDefinition.method;
  axiosOptions.withCredentials = true;              // allows sending and receiving cookies
  axiosOptions.responseType = callDefinition.responseType || 'json';
  if (verboseLogging) console.log('apiService.callApi() axiosOptions: ', axiosOptions);

  try {
    const response = await axios(axiosOptions);
    if (verboseLogging) console.log('apiService.callApi() response: ', response);
    if (callDefinition.responseType === 'blob') {
      // fileDownload(response.data, axiosOptions?.destinationFilename);
    } else {
      return response;
    }
  } catch (err) {
    if (verboseLogging) console.log('apiService.callApi() ERROR; err: ', err);
    throw err;
  }

};

export default callApi;
